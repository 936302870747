import React, { useState, useEffect } from 'react';
import './ScriptsModal.scss';

const ScriptsModal = ({ isOpen, onClose, theme }) => {
    const [selectedScript, setSelectedScript] = useState(null);
    const [repoStructure, setRepoStructure] = useState({});
    const [currentRepo, setCurrentRepo] = useState(null);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const repos = [
        { name: 'clubsilk-mp3-downloader', path: '' },
        { name: 'finsights', path: '' },
        { name: 'AragonAIO', path: '' },
    ];
    const owner = 'josharagon';

    const fetchDirectoryContents = async (repo, path = '') => {
        try {
            const response = await fetch(
                `https://api.github.com/repos/${owner}/${repo}/contents/${path}`
            );

            if (!response.ok) {
                console.warn(`Failed to fetch from ${repo}${path}: ${response.statusText}`);
                return [];
            }

            const data = await response.json();
            return Array.isArray(data) ? data : [data];
        } catch (error) {
            console.error(`Error fetching ${repo}${path}:`, error);
            return [];
        }
    };

    const buildRepoStructure = async (repo) => {
        const structure = { name: repo, type: 'directory', children: [] };
        const processDirectory = async (dirPath, currentNode) => {
            const contents = await fetchDirectoryContents(repo, dirPath);

            for (const item of contents) {
                if (item.type === 'file' && item.name.endsWith('.py')) {
                    currentNode.children.push({
                        name: item.name,
                        type: 'file',
                        path: item.path,
                        download_url: item.download_url
                    });
                } else if (item.type === 'dir') {
                    const newNode = { name: item.name, type: 'directory', children: [] };
                    currentNode.children.push(newNode);
                    await processDirectory(item.path, newNode);
                }
            }
        };

        await processDirectory('', structure);
        return structure;
    };

    useEffect(() => {
        const fetchAllRepos = async () => {
            const structure = {};
            for (const repo of repos) {
                structure[repo.name] = await buildRepoStructure(repo.name);
            }
            setRepoStructure(structure);
        };

        fetchAllRepos();
    }, []);

    const fetchScriptContent = async (downloadUrl) => {
        try {
            const response = await fetch(downloadUrl);
            const content = await response.text();
            return content;
        } catch (error) {
            console.error('Error fetching script content:', error);
            return 'Error loading file content';
        }
    };

    const FileTreeItem = ({ item, depth = 0 }) => {
        const handleFileClick = async (downloadUrl) => {
            const content = await fetchScriptContent(downloadUrl);
            setSelectedScript({
                name: item.name,
                code: content
            });
        };

        return (
            <div style={{ marginLeft: `${depth * 20}px` }} className="file-tree-item">
                {item.type === 'directory' ? (
                    <>
                        <div className="directory-name">
                            📁 {item.name}
                        </div>
                        {item.children.map((child, index) => (
                            <FileTreeItem key={index} item={child} depth={depth + 1} />
                        ))}
                    </>
                ) : (
                    <div
                        className="file-name"
                        onClick={() => handleFileClick(item.download_url)}
                    >
                        📄 {item.name}
                    </div>
                )}
            </div>
        );
    };

    if (!isOpen) return null;

    return (
        <div className="scripts-modal-overlay">
            <div className={`scripts-modal ${theme}`}>
                <button className="close-button" onClick={onClose}>×</button>
                <div className="modal-content">
                    <div className="file-explorer">
                        <h3>Repositories</h3>
                        {Object.entries(repoStructure).map(([repoName, structure]) => (
                            <div key={repoName} className="repo-section">
                                <h4>{repoName}</h4>
                                <FileTreeItem item={structure} />
                            </div>
                        ))}
                    </div>
                    <div className="file-content">
                        {selectedScript ? (
                            <>
                                <div className="file-header">
                                    <h3>{selectedScript.name}</h3>
                                </div>
                                <pre>
                                    <code>{selectedScript.code}</code>
                                </pre>
                            </>
                        ) : (
                            <div className="no-file-selected">
                                Select a file to view its contents
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScriptsModal;
